import {
    REFRESH_PENDING_DEPOSIT, PENDING_DEPOSIT_LIST_LOADED,
    REFRESH_PENDING_WITHDRAWAL, PENDING_WITHDRAWAL_LIST_LOADED,
    REFRESH_PENDING_TRANSFER, PENDING_TRANSFER_LIST_LOADED,
    REFRESH_NEW_USER_DATA, NEW_USER_DATA_LOADED,
    SHOW_ADD_DEPOSIT,
    ADD_TRANSACTION_TYPE
} from "../constants/action-types";

const initialState = {
    articles: [],
    refreshPendingDeposit: false,
    pendingDepositLists: [],
    refreshPendingWithdrawal: false,
    pendingWithdrawalLists: [],
    refreshPendingTransfer: false,
    pendingTransferLists: [],
    refreshNewUserData: false,
    newUserData: [],
    showAddDeposit: false,
    transactionTypeData: [],
    sidebarShow: 'responsive'
};

function rootReducer(state = initialState, action) {
    // console.log(action, 'action');
    if (action.type === REFRESH_PENDING_DEPOSIT) {
        return Object.assign({}, state, {
            refreshPendingDeposit: (typeof action.payload !== 'undefined') ? action.payload.refresh : state.refreshPendingDeposit
        });
    } else if (action.type === PENDING_DEPOSIT_LIST_LOADED) {
        return Object.assign({}, state, {
            pendingDepositLists: action.payload
        });
    } else if (action.type === REFRESH_PENDING_WITHDRAWAL) {
        return Object.assign({}, state, {
            refreshPendingWithdrawal: (typeof action.payload !== 'undefined') ? action.payload.refresh : state.refreshPendingWithdrawal
        });
    } else if (action.type === PENDING_WITHDRAWAL_LIST_LOADED) {
        return Object.assign({}, state, {
            pendingWithdrawalLists: action.payload
        });
    } else if (action.type === REFRESH_PENDING_TRANSFER) {
        return Object.assign({}, state, {
            refreshPendingTransfer: (typeof action.payload !== 'undefined') ? action.payload.refresh : state.refreshPendingWithdrawal
        });
    } else if (action.type === PENDING_TRANSFER_LIST_LOADED) {
        return Object.assign({}, state, {
            pendingTransferLists: action.payload
        });
    } else if (action.type === REFRESH_NEW_USER_DATA) {
        return Object.assign({}, state, {
            refreshNewUserData: (typeof action.payload !== 'undefined') ? action.payload.refresh : state.refreshNewUserData
        });
    }  else if (action.type === NEW_USER_DATA_LOADED) {
        return Object.assign({}, state, {
            newUserData: action.payload
        });
    }  else if (action.type === SHOW_ADD_DEPOSIT) {
        return Object.assign({}, state, {
            showAddDeposit: (typeof action.payload !== 'undefined') ? action.payload.show : state.showAddDeposit
        });
    } else if (action.type === ADD_TRANSACTION_TYPE) {
        return Object.assign({}, state, {
            transactionTypeData: (typeof action.payload !== 'undefined') ? action.payload : state.transactionTypeData
        });
    } else if (action.type === 'set') {
        return Object.assign({}, state, {
            sidebarShow: (typeof action.sidebarShow !== 'undefined') ? action.sidebarShow : state.sidebarShow
        });
    }
    return state;
};

export default rootReducer;