import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
// import './scss/theme1_style.scss';

import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
import { getToken, getUser, removeUserSession, setUserSession, setBankSession, setGameSession, setReferralSession, setPromotionSession, setAdminSession, setShiftSession, setSessionItem, loadReferralIconFile, getSessionItem } from './utils/Utils';
import { Helmet } from "react-helmet";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const theme = process.env.REACT_APP_THEME;
var loginFile = "Login";
if (theme === 'theme1') {
  loginFile = "LoginTheme1";
}
const Login = React.lazy(() => import(`./views/pages/login/${loginFile}`));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const Home = React.lazy(() => import('./views/dashboard/Dashboard'));
const Users = React.lazy(() => import('./views/users/Users'));
const User = React.lazy(() => import('./views/users/User'));
const FollowUp = React.lazy(() => import('./views/users/FollowUp'));
const FollowUpList = React.lazy(() => import('./views/users/FollowUpList'));
const DisplayUser = React.lazy(() => import('./views/users/DisplayUser'));
const Transaction = React.lazy(() => import('./views/transactions/List'));
const TransactionDepositDetail = React.lazy(() => import('./views/transactions/DepositDetail'));
const TransactionWithdrawalDetail = React.lazy(() => import('./views/transactions/WithdrawalDetail'));
const TransactionTransferDetail = React.lazy(() => import('./views/transactions/TransferDetail'));
const SaleReport = React.lazy(() => import('./views/reports/sales/List'));
const PublicReport = React.lazy(() => import('./views/reports/public/List'));
const TransactionReport = React.lazy(() => import('./views/reports/transactions/List'));
const Game = React.lazy(() => import('./views/games/List'));
const PoolId = React.lazy(() => import('./views/games/PoolIdList'));
const Bank = React.lazy(() => import('./views/banks/List'));
const Referral = React.lazy(() => import('./views/referrals/List'));
const SmsGateway = React.lazy(() => import('./views/settings/SmsGateway'));
const MaxWithdrawalCount = React.lazy(() => import('./views/settings/MaxWithdrawalCount'));
const Shift = React.lazy(() => import('./views/settings/Shift'));
const Target = React.lazy(() => import('./views/settings/Target'));
const Website = React.lazy(() => import('./views/settings/Website.js'));
const SuccessMessage = React.lazy(() => import('./views/settings/SuccessMessage.js'));
const Promotion = React.lazy(() => import('./views/promotions/List.js'));
const Employee = React.lazy(() => import('./views/employees/List.js'));
const Log = React.lazy(() => import('./views/logs/List.js'));
const Position = React.lazy(() => import('./views/roles/List.js'));
const BlacklistBank = React.lazy(() => import('./views/users/BlacklistBank.js'));
const Rank = React.lazy(() => import('./views/ranks/List'));
const FollowUpActiveUser = React.lazy(() => import('./views/users/FollowUpActiveUser'));
const SocialPlatform = React.lazy(() => import('./views/social-platforms/List'));
const GoogleAuth = React.lazy(() => import('./views/settings/GoogleAuth.js'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      authLoading: true,
    };
  }

  componentDidMount() {
    // if (getUser() !== null) {
    //   document.title = getUser().prefix.toUpperCase();
    // }
    const token = getToken();
    if (!token) {
      return;
    }
    var bytes = getSessionItem('bytes');
    const user = getUser();
    if (typeof user.id !== 'undefined') {
      bytes += user.id;
    }
    const request = new Request(window.domain + 'api/refresh', { method: 'POST', body: JSON.stringify({ refresh_token: token, bytes: bytes }), headers: { 'Api-Key': window.apiKey, "Content-Type": "application/json" } });
    fetch(request)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status === true || result.status === 'success') {
            setUserSession(result.data.token, result.data.user);
            setBankSession(result.data.banks);
            setGameSession(result.data.games);
            setReferralSession(result.data.referrals);
            setPromotionSession(result.data.promotions);
            setAdminSession(result.data.admins);
            setShiftSession(result.data.shifts);
            setSessionItem('path', result.data.path);
            setSessionItem('routes', result.data.routes);
            var url = window.domain + 'icons.php?path=assets/icons/sprites/brand.svg';
            loadReferralIconFile(url, 'iconListBrand');
            url = window.domain + 'icons.php?path=assets/icons/sprites/free.svg';
            loadReferralIconFile(url, 'iconListFree');
          } else {
            removeUserSession();
          }
          this.setState({
            authLoading: false
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          removeUserSession();
          this.setState({
            authLoading: false
          });
        }
      )

    if (this.authLoading && getToken()) {
      return <div className="content">Checking Authentication...</div>
    }

    // var script = document.createElement("script");
    // script.src = "./js/jquery.min.js";
    // script.async = true;
    // document.body.appendChild(script);    

    // setTimeout(function () {
    //   script = document.createElement("script");
    //   script.src = "./js/project.js?v=3.0";
    //   script.async = true;
    //   document.body.appendChild(script);
      
    //   script = document.createElement("script");
    //   script.src = "./js/jquery-ui.min.js";//"https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js";
    //   script.async = false;
    //   document.body.appendChild(script);
    // }, 500);
  }

  render() {
    return (
      <HashRouter>
        <Helmet>
          <title>{getUser() !== null ? getUser().prefix : ''}</title>
        </Helmet>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
        <Switch>
          {/* <Route exact path="/" component={Home} /> */}
          <PublicRoute path="/login" component={Login} />
          {/* <PublicRoute path="/public/report/sale/:code" component={PublicReport} /> */}
          <PrivateRoute path="/dashboard/:clear?" component={Dashboard} />
          <PrivateRoute path="/users" component={Users} />
          <PrivateRoute path="/user" component={User} />
          <PrivateRoute path="/display-user/:id" component={DisplayUser} />
          <PrivateRoute path="/follow-up" component={FollowUp} />
          <PrivateRoute path="/follow-up-list" component={FollowUpList} />
          <PrivateRoute path="/follow-up-active-user" component={FollowUpActiveUser} />
          <PrivateRoute path="/transaction/:type" component={Transaction} />
          <PrivateRoute path="/transaction-detail/deposit" component={TransactionDepositDetail} />
          <PrivateRoute path="/transaction-detail/withdrawal" component={TransactionWithdrawalDetail} />
          <PrivateRoute path="/transaction-detail/transfer" component={TransactionTransferDetail} />
          <PrivateRoute path="/report/sale" component={SaleReport} />
          <PrivateRoute path="/report/transaction" component={TransactionReport} />
          <PrivateRoute path="/setting/game" component={Game} />
          <PrivateRoute path="/game/pool-id" component={PoolId} />
          <PrivateRoute path="/setting/bank" component={Bank} />
          <PrivateRoute path="/setting/referral" component={Referral} />
          <PrivateRoute path="/setting/sms-gateway" component={SmsGateway} />
          <PrivateRoute path="/setting/max-withdrawal-count" component={MaxWithdrawalCount} />
          <PrivateRoute path="/setting/shift" component={Shift} />
          <PrivateRoute path="/setting/target" component={Target} />
          <PrivateRoute path="/setting/website" component={Website} />
          <PrivateRoute path="/setting/success-message" component={SuccessMessage} />
          <PrivateRoute path="/setting/promotion" component={Promotion} />
          <PrivateRoute path="/setting/employee" component={Employee} />
          <PrivateRoute path="/setting/rank" component={Rank} />

          <PrivateRoute path="/log" component={Log} />
          <PrivateRoute path="/position" component={Position} />
          <PrivateRoute path="/blacklist-bank" component={BlacklistBank} />
          <PrivateRoute path="/setting/social-platform" component={SocialPlatform} />
          <PrivateRoute path="/setting/google-auth" component={GoogleAuth} />

        </Switch>
      </HashRouter>
    );
  }
}

export default App;
