import { takeEvery, call, put } from "redux-saga/effects";
import { getToken } from '../../utils/Utils';
import {
    NEW_USER_DATA_REQUESTED, REFRESH_NEW_USER_DATA, NEW_USER_DATA_LOADED,
} from "../constants/action-types";

export default function* watcherSaga() {
    yield takeEvery(NEW_USER_DATA_REQUESTED, workerSaga);
}

function* workerSaga() {
    try {
        var payload = yield call(getNewUserData);
        yield put({ type: NEW_USER_DATA_LOADED, payload });
        payload = yield call(refreshNewUserData);
        yield put({ type: REFRESH_NEW_USER_DATA, payload });
    } catch (e) {
        yield put({ type: "API_ERRORED", payload: e });
    }
}

function getNewUserData() {
    const a = fetch(window.domain + 'api/app/user/get-new-user-data',
        {
            method: "POST",
            headers: {
                'Api-Key': window.apiKey,
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(res => res.json()).then(result => result.data);
    return a;
}

function refreshNewUserData() {
    return { refresh: false };
}