import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import your fontawesome library
import './fontawesome';
// import App from './js/components/App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
// import store from './store'

import store from "./js/store/index";
// import index from "./js/index";

import { getToken, getUser } from "./utils/Utils"
import { dom } from '@fortawesome/fontawesome-svg-core';
import encHex from 'crypto-js/enc-hex'

import Echo from "laravel-echo"
// import { io, Socket } from "socket.io-client";

// window.store = store;
// window.addArticle = addArticle;

var domain = window.location.host;
var host = domain.split(".");
var isLocal = false;
if (domain.indexOf('localhost') > -1) {
  isLocal = true;
}
var len = host.length;
if (!isLocal) {
  if (host[0] == 'one') {
    domain = 'https://api.' + host[len - 2] + '.' + host[len - 1] + '/';  
  } else if (host[0] === 'theme1') {
    domain = 'https://api-demo-back.' + host[len - 2] + '.' + host[len - 1] + '/';  
  } else {
    domain = 'https://api-' + host[0] + '.' + host[len - 2] + '.' + host[len - 1] + '/';
  }
} else {
  domain = 'http://localhost:8005/';
}
if (domain.indexOf('-backup') > -1) {  
  domain = 'https://api-' + host[0] + '.' + host[len - 2] + '.' + host[len - 1] + '/';
}
// window.domain = window.location.host.indexOf('localhost') > -1 ? 'http://localhost:8005/' : 'https://api2.mente-xyz.com/';
// if (window.location.host.indexOf('-backup') > -1) {
//   window.domain = 'https://api-backup.mente-xyz.com/';
// }
// domain = 'https://api-ninjaity-acceptance.supercook.xyz/';
window.domain = domain;
window.apiKey = 'l1hlnlmlsdasd&^(@dfg2809734lknslkfd';

var k1 = 'a4486edae7aa3faac5172b9245bed2af';
var k2 = '250e68c96974af0134d75507d822ef22';
window.key = encHex.parse(k1);
window.iv =  encHex.parse(k2);
window.appname = process.env.REACT_APP_APPNAME;
// store.subscribe(() => console.log('Look ma, Redux!!'));
// store.dispatch( addArticle({ title: 'React Redux Tutorial for Beginners', id: 1 }) );

window.theme = '';
window.theme = process.env.REACT_APP_THEME;
React.icons = icons

// window.io = require('socket.io-client');

if (!window.Echo) {
  // var socketHost;
  // // if (window.location.hostname.indexOf('localhost') > -1)
  //   socketHost = window.location.hostname + ':6001';
  // // else
  //   socketHost = 'api-' + window.location.hostname + ':6001';
  // // socketHost = 'api-rs2.super1ch.com:6001';
  // window.Echo = new Echo({
  //   broadcaster: "socket.io",
  //   // connector: 'socket.io',
  //   host: socketHost,
  //   transports: ["websocket", "polling", "flashsocket"],
  //   enabledTransports: ['ws', 'wss'], // <-- only use ws and wss as valid transports
  // });
  // window.Echo.channel('kiosk-credit-');

  var key = 'efd0a632c3d81a440382';//localhost-ls
  var href = window.location.href;
  if (href.indexOf('rs') > -1 || href.indexOf('super1ch.com') > -1) {
    key = 'c9eb448f92121f4c7559';
  } else if (href.indexOf('xb23') > -1 || href.indexOf('ninjaity') > -1) {
    key = 'e0a3372b4a26988f9c94';
  } else if (window.location.href.indexOf('zt88') > -1) {
    key = 'c83842471ec58c04608c';
  } else if (window.location.href.indexOf('mj88') > -1) {
    key = '3e54eba79e12f9687f60';
  } else if (window.location.href.indexOf('makayla') > -1 || window.location.href.indexOf('r8') > -1 || window.location.href.indexOf('superb-star-88.xyz') > -1) {
    key = 'a61dfe7c9a11096f2ac5';
  } else if (window.location.href.indexOf('jw88') > -1) {
    key = '053f1b5a9e29a73cf484';
  } else if (window.location.href.indexOf('jf88') > -1) {
    key = 'd021083a953349f77b7c';
  }
  
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: key,
    cluster: 'ap1',
    forceTLS: true
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
