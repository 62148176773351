export const ADD_ARTICLE = "ADD_ARTICLE";
export const REFRESH_PENDING_DEPOSIT = "REFRESH_PENDING_DEPOSIT";
export const PENDING_DEPOSIT_LIST_REQUESTED = "PENDING_DEPOSIT_LIST_REQUESTED";
export const PENDING_DEPOSIT_LIST_LOADED = "PENDING_DEPOSIT_LIST_LOADED";

export const REFRESH_PENDING_WITHDRAWAL = "REFRESH_PENDING_WITHDRAWAL";
export const PENDING_WITHDRAWAL_LIST_REQUESTED = "PENDING_WITHDRAWAL_LIST_REQUESTED";
export const PENDING_WITHDRAWAL_LIST_LOADED = "PENDING_WITHDRAWAL_LIST_LOADED";

export const REFRESH_PENDING_TRANSFER = "REFRESH_PENDING_TRANSFER";
export const PENDING_TRANSFER_LIST_REQUESTED = "PENDING_TRANSFER_LIST_REQUESTED";
export const PENDING_TRANSFER_LIST_LOADED = "PENDING_TRANSFER_LIST_LOADED";

export const REFRESH_NEW_USER_DATA = "REFRESH_NEW_USER_DATA"; 
export const NEW_USER_DATA_REQUESTED = "NEW_USER_DATA_REQUESTED";
export const NEW_USER_DATA_LOADED = "NEW_USER_DATA_LOADED";

export const SHOW_ADD_DEPOSIT = "SHOW_ADD_DEPOSIT";

export const ADD_TRANSACTION_TYPE = "ADD_TRANSACTION_TYPE"; 