// import the library
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faInternetExplorer 
} from '@fortawesome/free-brands-svg-icons';

// import your icons
import {
    faCircleNotch,
    faCheck, 
    faTimes, 
    faSpinner, 
    faGamepad, 
    faSyncAlt, faExclamationTriangle, 
    faAngleDown, 
    faAngleUp, 
    faList, 
    faPlus, 
    faChartLine, 
    faCalendar, 
    faUniversity, 
    faUser, 
    faSms, 
    faCog,
    faBriefcase,
    faPercent,
    faUsers,
    faCogs,
    faStreetView,
    faMoneyBill,
    faComment,
    faSignOutAlt,
    faDesktop,
    faAngleDoubleUp,
    faLock,
    faCoins,
    faUserCheck,
    faHandHoldingUsd
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faCircleNotch,
    faCheck,
    faTimes,
    faSpinner,
    faGamepad,
    faSyncAlt,
    faExclamationTriangle,
    faList,
    faAngleDown,
    faAngleUp,
    faPlus,
    faChartLine,
    faCalendar,
    faUniversity,
    faUser,
    faSms,
    faCog,
    faBriefcase,
    faPercent,
    faInternetExplorer,
    faUsers,
    faCogs,
    faStreetView,
    faMoneyBill,
    faComment,
    faSignOutAlt,
    faDesktop,
    faAngleDoubleUp,
    faLock,
    faCoins,
    faUserCheck,
    faUsers,
    faHandHoldingUsd
    // more icons go here
);