import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from "../reducers/index"
import { forbiddenWordsMiddleware } from "../middleware/index"
import createSagaMiddleware from "redux-saga"
import apiSagaDeposit from "../sagas/api-saga-pending-deposit"
import apiSagaWithdrawal from "../sagas/api-saga-pending-withdrawal"
import apiSagaTransfer from "../sagas/api-saga-pending-transfer"
import apiSagaNewUserData from "../sagas/api-saga-new-user-data"

const initialiseSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    storeEnhancers(
        applyMiddleware(forbiddenWordsMiddleware, initialiseSagaMiddleware)
    )
);

initialiseSagaMiddleware.run(apiSagaDeposit);
initialiseSagaMiddleware.run(apiSagaWithdrawal);
initialiseSagaMiddleware.run(apiSagaTransfer);
initialiseSagaMiddleware.run(apiSagaNewUserData);

export default store;