import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Utils';

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
    const p = rest.location.pathname;
    return (
        <Route
            {...rest}
            render={(props) => getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}            
        >
            <Suspense fallback={<h2>loading...</h2>}>
                <Redirect to={!getToken() ? { pathname: '/login' } : { pathname: p}} /> 
            </Suspense>
        </Route>
    )
}

export default PrivateRoute;