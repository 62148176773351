import { takeEvery, call, put } from "redux-saga/effects";
import { getToken } from '../../utils/Utils';
import {
    PENDING_DEPOSIT_LIST_REQUESTED, REFRESH_PENDING_DEPOSIT, PENDING_DEPOSIT_LIST_LOADED,
} from "../constants/action-types";

export default function* watcherSaga() {
    yield takeEvery(PENDING_DEPOSIT_LIST_REQUESTED, workerSaga);
}

function* workerSaga() {
    try {
        var payload = yield call(getPendingDepositList);
        yield put({ type: PENDING_DEPOSIT_LIST_LOADED, payload });
        payload = yield call(refreshPendingDepositDone);
        yield put({ type: REFRESH_PENDING_DEPOSIT, payload });
    } catch (e) {
        yield put({ type: "API_ERRORED", payload: e });
    }
}

function getPendingDepositList() {
    const a = fetch(window.domain + 'api/app/transaction/deposit/pending-list', {
        headers: {
            'Api-Key': window.apiKey,
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(res => res.json()).then(result => result.data);
    return a;
}

function refreshPendingDepositDone() {
    return { refresh: false };
}